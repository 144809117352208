export type LogLevel = "log" | "info" | "error" | "warn" | "geometry" | "guide";

export class LogMessage {
  constructor(
    public readonly message: unknown,
    public readonly logLevel: LogLevel,
    public readonly line?: number // Number 1 refers to the first line.
  ) {}

  withLineNumber(line: number) {
    return new LogMessage(this.message, this.logLevel, line);
  }

  static fromArgs(logLevel: LogLevel, args: unknown[]) {
    let message: unknown = args;
    if (args.length === 1) {
      message = args[0];
    }
    return new LogMessage(message, logLevel);
  }

  toString(): string {
    return String(this.message);
  }
}

export type LogMessageSubscriber = (message: LogMessage) => void;

class LogManager {
  subscribers: LogMessageSubscriber[] = [];

  subscribe(subscriber: LogMessageSubscriber) {
    this.subscribers.push(subscriber);
  }
  unsubscribe(subscriber: LogMessageSubscriber) {
    const activeSubscriber = this.subscribers.pop();
    if (subscriber !== activeSubscriber) {
      throw "Tried to unsubscribe an inactive subscriber!";
    }
  }

  private activeSubscriber() {
    return this.subscribers[this.subscribers.length - 1];
  }

  consoleMessage(message: LogMessage) {
    const subscriber = this.activeSubscriber();
    if (subscriber) {
      subscriber(message);
    } else {
      console.warn("Message logged, but no one was listening! 🍃", message);
    }
  }
  console(logLevel: LogLevel, ...args: unknown[]) {
    const message = LogMessage.fromArgs(logLevel, args);
    this.consoleMessage(message);
  }

  consoleLog(...args: unknown[]) {
    this.console("log", ...args);
  }

  consoleInfo(...args: unknown[]) {
    this.console("info", ...args);
  }
  consoleWarn(...args: unknown[]) {
    this.console("warn", ...args);
  }
  consoleError(...args: unknown[]) {
    this.console("error", ...args);
  }

  consoleGeometry(...args: unknown[]) {
    this.console("geometry", ...args);
  }
  consoleGuide(...args: unknown[]) {
    this.console("guide", ...args);
  }
}

// We're trying to avoid this singleton instantiation pattern, but in this case
// we promise that this file is very decoupled and won't have any dependencies.
export const logManager = new LogManager();
