import * as opentype from "opentype.js";

import {
  AffineMatrix,
  BoundingBox,
  ClosestPointResult,
  Fill,
  Graphic,
  ImageFill,
  Path,
  RasterizeOptions,
  Stroke,
  Vec,
  boundingBoxForGeometries,
  looseBoundingBoxForGeometries,
  pathContainsPoint,
  pathStyleContainsPoint,
  rasterizeGraphic,
} from "..";
import {
  PathKit,
  copyPkPath,
  emptyPkPath,
  fromPkCommands,
  fromPkPath,
  pathKitScaleFactorForBoundingBox,
  performQualityAssuredOp,
  performStroke,
  pkPathFromSVGPathString,
  toPkPath,
} from "../misc/pathkit";

/**
 * A path made up of several sub-paths. Cuttle uses the "evenodd" fill rule when
 * rendering compound paths. This means that two overlapping sub-paths will
 * create a hole.
 */
export class CompoundPath extends Graphic {
  static readonly displayName = "CompoundPath";

  /** The array of sub-paths */
  paths: Path[];

  /** The stroke style */
  stroke?: Stroke;

  /** The stroke style */
  fill?: Fill | ImageFill;

  /**
   * Constructs a compound path from one or more sub-paths.
   *
   * @param paths An array of paths
   * @param stroke A stroke style to assign
   * @param fill A fill style to assign
   */
  constructor(paths: Path[] = [], stroke?: Stroke, fill?: Fill | ImageFill) {
    super();
    this.paths = paths;
    this.stroke = stroke;
    this.fill = fill;
  }

  clone() {
    return new CompoundPath(
      this.paths.map((path) => path.clone()),
      this.stroke?.clone(),
      this.fill?.clone()
    );
  }

  isValid() {
    return (
      Array.isArray(this.paths) &&
      (this.stroke === undefined || Stroke.isValid(this.stroke)) &&
      (this.fill === undefined || Fill.isValid(this.fill) || ImageFill.isValid(this.fill)) &&
      this.paths.every(Path.isValid)
    );
  }

  affineTransform(affineMatrix: AffineMatrix) {
    for (let path of this.paths) path.affineTransform(affineMatrix);
    if (this.fill instanceof ImageFill) {
      this.fill.affineTransform(affineMatrix);
    }
    return this;
  }

  affineTransformWithoutTranslation(affineMatrix: AffineMatrix) {
    for (let path of this.paths) path.affineTransformWithoutTranslation(affineMatrix);
    if (this.fill instanceof ImageFill) {
      this.fill.affineTransformWithoutTranslation(affineMatrix);
    }
    return this;
  }

  allCompoundPaths() {
    return [this];
  }

  allPaths() {
    return [...this.paths];
  }

  allAnchors() {
    return this.paths.flatMap((p) => p.anchors);
  }

  allPathsAndCompoundPaths() {
    return [this];
  }

  hasStyle() {
    return Boolean(this.stroke) || Boolean(this.fill);
  }

  hasStrokeWithNonStandardAlignment() {
    return this.stroke && !this.stroke.hairline && this.stroke.alignment !== "centered";
  }

  assignFill(fill: Fill | ImageFill) {
    this.fill = fill.clone();
    for (let path of this.paths) path.assignFill(fill);
    return this;
  }
  removeFill() {
    this.fill = undefined;
    for (let path of this.paths) path.removeFill();
    return this;
  }

  assignStroke(stroke: Stroke) {
    this.stroke = stroke.clone();
    for (let path of this.paths) path.assignStroke(stroke);
    return this;
  }
  removeStroke() {
    this.stroke = undefined;
    for (let path of this.paths) path.removeStroke();
    return this;
  }

  assignStyle(fill: Fill, stroke: Stroke) {
    this.stroke = stroke?.clone();
    this.fill = fill?.clone();
    for (let path of this.paths) path.assignStyle(fill, stroke);
    return this;
  }

  copyStyle(graphic: Graphic) {
    const styledGraphic = graphic.firstStyled();
    if (styledGraphic) {
      if (styledGraphic.fill) this.assignFill(styledGraphic.fill);
      if (styledGraphic.stroke) this.assignStroke(styledGraphic.stroke);
    }
    return this;
  }

  scaleStroke(scaleFactor: number) {
    if (this.stroke && !this.stroke.hairline) {
      this.stroke.width *= scaleFactor;
    }
    return this;
  }

  firstStyled() {
    if (this.fill || this.stroke) return this;
    return undefined;
  }

  toSVGPathString(maxPrecision?: number) {
    return this.paths.map((path) => path.toSVGPathString(maxPrecision)).join("");
  }

  looseBoundingBox() {
    return looseBoundingBoxForGeometries(this.paths);
  }
  boundingBox() {
    return boundingBoxForGeometries(this.paths);
  }

  isContainedByBoundingBox(box: BoundingBox) {
    const bounds = this.boundingBox();
    return !!bounds && box.containsBoundingBox(bounds);
  }

  isIntersectedByBoundingBox(box: BoundingBox) {
    return this.paths.some((path) => path.isIntersectedByBoundingBox(box));
  }

  isOverlappedByBoundingBox(box: BoundingBox) {
    return this.paths.some((path) => path.isOverlappedByBoundingBox(box));
  }

  closestPoint(point: Vec, areaOfInterest?: BoundingBox): ClosestPointResult | undefined {
    const { paths } = this;

    if (paths.length === 0) return undefined;
    if (paths.length === 1) return paths[0].closestPoint(point);

    let closestResult: ClosestPointResult | undefined;
    let closestDistanceSq = Infinity;

    for (let path of paths) {
      const result = path.closestPoint(point, areaOfInterest);
      if (!result) continue;

      const distanceSq = point.distanceSquared(result.position);
      if (distanceSq < closestDistanceSq) {
        // We ignore time here since there in no way to determine which path it
        // came from.
        closestResult = result;
        closestDistanceSq = distanceSq;
      }
    }

    return closestResult;
  }

  _primitives() {
    return this.paths.flatMap((path) => path._primitives());
  }

  containsPoint(point: Vec) {
    return pathContainsPoint(this, point);
  }

  styleContainsPoint(point: Vec) {
    return pathStyleContainsPoint(this, point);
  }

  reverse() {
    for (let path of this.paths) path.reverse();
    this.paths.reverse();
    return this;
  }

  rasterize(options?: RasterizeOptions): Path | undefined {
    const res = rasterizeGraphic(this, options);
    if (!res) return;
    const imagePath = Path.fromBoundingBox(res.boundingBox);
    imagePath.fill = res.fill;
    return imagePath;
  }

  static isValid = (a: unknown): a is CompoundPath => {
    return a instanceof CompoundPath && a.isValid();
  };

  /**
   * Constructs a compound path from a SVG path string.
   *
   * Path strings are a compact representation of canvas-style (move to, line
   * to, etc) drawing commands used by the SVG `<path>` element.
   *
   * See: [Path commands reference on
   * MDN](https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d#path_commands)
   *
   * @param svgPathString A valid SVG path string
   */
  static fromSVGPathString = (svgPathString: string) => {
    const pkPath = pkPathFromSVGPathString(svgPathString);
    return fromPkPath(pkPath, 1, true);
  };

  static fromOpenTypePath = (openTypePath: opentype.Path) => {
    const pkCommands = openTypePath.commands.map((command) => {
      if (command.type === "M") {
        return [PathKit.MOVE_VERB, command.x, command.y];
      }
      if (command.type === "L") {
        return [PathKit.LINE_VERB, command.x, command.y];
      }
      if (command.type === "C") {
        return [
          PathKit.CUBIC_VERB,
          command.x1,
          command.y1,
          command.x2,
          command.y2,
          command.x,
          command.y,
        ];
      }
      if (command.type === "Q") {
        return [PathKit.QUAD_VERB, command.x1, command.y1, command.x, command.y];
      }
      // type === "Z"
      return [PathKit.CLOSE_VERB];
    });
    return fromPkCommands(pkCommands);
  };

  static booleanUnion = (graphics: Graphic[], fillRule?: "evenodd" | "winding"): CompoundPath => {
    const paths = graphics.flatMap((item) => item.allPathsAndCompoundPaths());
    const result = performQualityAssuredOp(paths, fillRule, (pkPaths) => {
      const resultPkPath = emptyPkPath();
      for (const pkPath of pkPaths) {
        resultPkPath.op(pkPath, PathKit.PathOp.UNION);
      }
      return resultPkPath;
    });
    return result;
  };

  static booleanIntersect(graphics: Graphic[]): CompoundPath {
    const result = performQualityAssuredOp(graphics, "evenodd", (pkPaths) => {
      let resultPkPath: any = undefined; // TODO: Types for PathKit
      for (const pkPath of pkPaths) {
        if (resultPkPath === undefined) {
          resultPkPath = copyPkPath(pkPath);
        } else {
          resultPkPath.op(pkPath, PathKit.PathOp.INTERSECT);
        }
      }
      return resultPkPath;
    });
    return result;
  }

  static booleanDifference(graphics: Graphic[]): CompoundPath {
    const result = performQualityAssuredOp(graphics, "evenodd", (pkPaths) => {
      let resultPkPath: any = undefined; // TODO: Types for PathKit
      for (const pkPath of pkPaths) {
        if (resultPkPath === undefined) {
          resultPkPath = copyPkPath(pkPath);
        } else {
          resultPkPath.op(pkPath, PathKit.PathOp.DIFFERENCE);
        }
      }
      return resultPkPath;
    });
    return result;
  }

  static stroke(item: Graphic, opts: StrokeOptions = {}) {
    let { width, miterLimit, join, cap } = opts;
    if (width === undefined) width = 1;
    if (cap === undefined) cap = "butt";
    if (join === undefined) join = "miter";
    if (miterLimit === undefined) miterLimit = 4;

    const bounds = item.looseBoundingBox();
    if (!bounds) return new CompoundPath();
    const scaleFactor = pathKitScaleFactorForBoundingBox(bounds);

    const pkPath = toPkPath(item, scaleFactor);
    performStroke(pkPath, width * scaleFactor, cap, join, miterLimit);

    return fromPkPath(pkPath, scaleFactor, true);
  }
}

export interface StrokeOptions {
  width?: number;
  cap?: "butt" | "round" | "square";
  join?: "miter" | "round" | "bevel";
  miterLimit?: number;
}
