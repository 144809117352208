import { Vec } from "../geom";
import { canonicalAssetsOrigin } from "../shared/config";
import { RotationalRepeatDefinition, RoundCornersDefinition } from "./builtin-modifiers";
import {
  AnchorDefinition,
  FillDefinition,
  GroupDefinition,
  PathDefinition,
  TransformDefinition,
} from "./builtin-primitives";
import { CodeComponent, Component } from "./component";
import { Element } from "./element";
import { Expression } from "./expression";
import { Instance } from "./instance";
import {
  Parameter,
  ParameterFolder,
  makeAngleParameter,
  makeBooleanParameter,
  makeCountParameter,
  makeDistanceParameter,
  makeEmojiParameter,
  makeFontSelectParameter,
  makeImageParameter,
  makePercentageParameter,
  makePointParameter,
  makeScalarParameter,
  makeSelectParameter,
  makeSizeParameter,
  makeTextParameter,
} from "./parameter";
import { PIScalar } from "./parameter-interface";
import { registerBuiltin } from "./registry";

// Guides

export const PointGuideDefinition = new CodeComponent();
PointGuideDefinition.isImmutable = true;
PointGuideDefinition.isPassThrough = true;
PointGuideDefinition.isShowGuides = true;
PointGuideDefinition.name = "Point Guide";
PointGuideDefinition.parameters = [makePointParameter("position", new Vec(0, 0))];
PointGuideDefinition.code = new Expression(`console.guide(position);`);
PointGuideDefinition.defaultStyle = "none";
registerBuiltin("PointGuideDefinition", PointGuideDefinition);

export const LineGuideDefinition = new CodeComponent();
LineGuideDefinition.isImmutable = true;
LineGuideDefinition.isPassThrough = true;
LineGuideDefinition.isShowGuides = true;
LineGuideDefinition.name = "Line Guide";
LineGuideDefinition.parameters = [
  makePointParameter("origin", new Vec(0, 0)),
  makeAngleParameter("angle", 0),
];
LineGuideDefinition.code = new Expression(`console.guide(Axis(origin, Vec.fromAngle(angle)));`);
LineGuideDefinition.defaultStyle = "none";
registerBuiltin("LineGuideDefinition", LineGuideDefinition);

// Graphics

export let CircleDefinition: Component;
{
  const anchor = new Element(new Instance(AnchorDefinition));
  anchor.name = "Anchor 1";
  anchor.base.args.position = new Expression("Vec(0.5, 0)");
  anchor.base.args.handleIn = new Expression("Vec(0, -0.275957512247)");
  anchor.base.args.handleOut = new Expression("Vec(0, 0.275957512247)");
  anchor.base.args.handleConstraint = new Expression(`"tangent"`);

  const rotationalRepeat = new Instance(RotationalRepeatDefinition);
  rotationalRepeat.args.repetitions = new Expression("4");
  anchor.modifiers = [rotationalRepeat];

  const path = new Element(new Instance(PathDefinition));
  path.name = "Path 1";
  path.children = [anchor];
  path.base.args.closed = new Expression("true");

  CircleDefinition = new Component(new Element(new Instance(GroupDefinition)));
  CircleDefinition.isImmutable = true;
  CircleDefinition.isAutoScale = true;
  CircleDefinition.isShowGuides = true;
  CircleDefinition.name = "Circle";
  CircleDefinition.element.children = [path];
  registerBuiltin("CircleDefinition1", CircleDefinition);
}

export const CircleFromCenterAndPointDefinition = new CodeComponent();
CircleFromCenterAndPointDefinition.isImmutable = true;
CircleFromCenterAndPointDefinition.isPassThrough = true;
CircleFromCenterAndPointDefinition.isShowGuides = true;
CircleFromCenterAndPointDefinition.isAutoScale = true;
CircleFromCenterAndPointDefinition.name = "Circle From Center And Point";
CircleFromCenterAndPointDefinition.parameters = [
  makePointParameter("center", new Vec(0, 0)),
  makePointParameter("point", new Vec(0.5, 0)),
];
CircleFromCenterAndPointDefinition.code = new Expression(
  `console.guide(center);
return Circle().transform({
  position: center,
  scale: center.distance(point) * 2,
  rotation: (point - center).angle(),
});`
);
registerBuiltin("CircleFromCenterAndPointDefinition", CircleFromCenterAndPointDefinition);

export const CircleFromTwoPointsDefinition = new CodeComponent();
CircleFromTwoPointsDefinition.isImmutable = true;
CircleFromTwoPointsDefinition.isPassThrough = true;
CircleFromTwoPointsDefinition.isShowGuides = true;
CircleFromTwoPointsDefinition.isAutoScale = true;
CircleFromTwoPointsDefinition.name = "Circle From Two Points";
CircleFromTwoPointsDefinition.parameters = [
  makePointParameter("point1", new Vec(-0.5, 0.0)),
  makePointParameter("point2", new Vec(0.5, 0.0)),
];
CircleFromTwoPointsDefinition.code = new Expression(
  `const center = mix(point1, point2, 0.5);
console.guide(center);
return Circle().transform({
  position: center,
  scale: point1.distance(point2),
  rotation: (point2 - point1).angle(),
});`
);
registerBuiltin("CircleFromTwoPointsDefinition", CircleFromTwoPointsDefinition);

export const CircleFromThreePointsDefinition = new CodeComponent();
CircleFromThreePointsDefinition.isImmutable = true;
CircleFromThreePointsDefinition.isPassThrough = true;
CircleFromThreePointsDefinition.isShowGuides = true;
CircleFromThreePointsDefinition.isAutoScale = true;
CircleFromThreePointsDefinition.name = "Circle From Three Points";
CircleFromThreePointsDefinition.parameters = [
  makePointParameter("point1", new Vec(-0.5, 0.0)),
  makePointParameter("point2", new Vec(0, -0.5)),
  makePointParameter("point3", new Vec(0.5, 0)),
];
CircleFromThreePointsDefinition.code = new Expression(
  `const lineLineIntersection = (p1, p2, p3, p4) => {
  const denom = (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);
  return ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denom;
};

const mid1 = mix(point1, point2, 0.5);
const mid2 = mix(point2, point3, 0.5);
const bisector1 = (point2 - point1).rotateNeg90();
const bisector2 = (point3 - point2).rotateNeg90();
const d = lineLineIntersection(mid1, bisector1 + mid1, mid2, bisector2 + mid2);
if (!Number.isFinite(d)) {
  console.geometry(Axis(point1, point3 - point1));
  throw new Error("The three point parameters are in a line, so we can't make a circle from them.");
}
const center = bisector1 * d + mid1;
console.guide(center);

return Circle().transform({
  position: center,
  scale: point1.distance(center) * 2,
});`
);
registerBuiltin("CircleFromThreePointsDefinition", CircleFromThreePointsDefinition);

export let RectangleDefinition: Component;
{
  const anchor1 = new Element(new Instance(AnchorDefinition));
  anchor1.name = "Anchor 1";
  anchor1.base.args.position = new Expression("Vec(-0.5, -0.5)");
  const anchor2 = new Element(new Instance(AnchorDefinition));
  anchor2.name = "Anchor 2";
  anchor2.base.args.position = new Expression("Vec(0.5, -0.5)");
  const anchor3 = new Element(new Instance(AnchorDefinition));
  anchor3.name = "Anchor 3";
  anchor3.base.args.position = new Expression("Vec(0.5, 0.5)");
  const anchor4 = new Element(new Instance(AnchorDefinition));
  anchor4.name = "Anchor 4";
  anchor4.base.args.position = new Expression("Vec(-0.5, 0.5)");

  const path = new Element(new Instance(PathDefinition));
  path.name = "Path 1";
  path.children = [anchor1, anchor2, anchor3, anchor4];
  path.base.args.closed = new Expression("true");

  const center = new Element(new Instance(PointGuideDefinition));
  center.name = "Point Guide 1";
  center.base.args.position = new Expression("Vec(0.0, 0.0)");

  RectangleDefinition = new Component(new Element(new Instance(GroupDefinition)));
  RectangleDefinition.isImmutable = true;
  RectangleDefinition.isDefaultUniformScale = false;
  RectangleDefinition.isAutoScale = true;
  RectangleDefinition.isShowGuides = true;
  RectangleDefinition.name = "Rectangle";
  RectangleDefinition.element.children = [path, center];
  registerBuiltin("RectangleDefinition2", RectangleDefinition);
}

export let PolygonDefinition: Component;
{
  const anchor = new Element(new Instance(AnchorDefinition));
  anchor.name = "Anchor 1";
  anchor.base.args.position = new Expression("Vec(0, -0.5)");

  const rotationalRepeat = new Instance(RotationalRepeatDefinition);
  rotationalRepeat.args.repetitions = new Expression("sides");
  anchor.modifiers = [rotationalRepeat];

  const path = new Element(new Instance(PathDefinition));
  path.name = "Path 1";
  path.base.args.closed = new Expression("true");
  path.children = [anchor];

  PolygonDefinition = new Component(new Element(new Instance(GroupDefinition)));
  PolygonDefinition.isImmutable = true;
  PolygonDefinition.isAutoScale = true;
  PolygonDefinition.isShowGuides = true;
  PolygonDefinition.name = "Polygon";
  PolygonDefinition.parameters = [makeCountParameter("sides", 5)];
  PolygonDefinition.element.children = [path];
  registerBuiltin("PolygonDefinition1", PolygonDefinition);
}

export let StarDefinition: Component;
{
  const anchor1 = new Element(new Instance(AnchorDefinition));
  anchor1.name = "Anchor 1";
  anchor1.base.args.position = new Expression("Vec(0, -0.5)");

  const anchor2 = new Element(new Instance(AnchorDefinition));
  anchor2.name = "Anchor 2";
  anchor2.base.args.position = new Expression(
    "Vec(0, -0.5 * innerRadius).rotate(360 / points / 2)"
  );

  const group = new Element(new Instance(GroupDefinition));
  group.name = "Group 1";
  group.children = [anchor1, anchor2];

  const rotationalRepeat = new Instance(RotationalRepeatDefinition);
  rotationalRepeat.args.repetitions = new Expression("points");
  group.modifiers = [rotationalRepeat];

  const path = new Element(new Instance(PathDefinition));
  path.name = "Path 1";
  path.base.args.closed = new Expression("true");
  path.children = [group];

  StarDefinition = new Component(new Element(new Instance(GroupDefinition)));
  StarDefinition.isImmutable = true;
  StarDefinition.isAutoScale = true;
  StarDefinition.isShowGuides = true;
  StarDefinition.name = "Star";
  StarDefinition.parameters = [
    makeCountParameter("points", 5),
    makePercentageParameter("innerRadius", 0.5, 2),
  ];
  StarDefinition.element.children = [path];
  registerBuiltin("StarDefinition", StarDefinition);
}

export const PolygonFromSideDefinition = new CodeComponent();
PolygonFromSideDefinition.isImmutable = true;
PolygonFromSideDefinition.isPassThrough = true;
PolygonFromSideDefinition.isShowGuides = true;
PolygonFromSideDefinition.isAutoScale = true;
PolygonFromSideDefinition.name = "Polygon From Side";
PolygonFromSideDefinition.parameters = [
  makePointParameter("point1", new Vec(0, 0)),
  makePointParameter("point2", new Vec(1, 0)),
  makeCountParameter("sides", 5),
];
PolygonFromSideDefinition.code = new Expression(
  `if (sides > 1000) {
  throw new Error('Polygon is limited to 1000 sides.');
}

const polygon = Polygon({ sides }).items[0];
const halfAngle = 180 / sides;

const matrix = AffineMatrix.fromTranslation(point1);
matrix.rotate((point2 - point1).angle() - halfAngle);
matrix.scaleScalar(point1.distance(point2) / sin(halfAngle));
matrix.translate(Vec(0, 0.5));

console.guide(Vec(matrix.tx, matrix.ty));

return polygon.affineTransform(matrix);`
);
registerBuiltin("PolygonFromSideDefinition", PolygonFromSideDefinition);

export const LineDefinition = new CodeComponent();
LineDefinition.isImmutable = true;
LineDefinition.isPassThrough = true;
LineDefinition.isShowGuides = true;
LineDefinition.isDefaultUniformScale = false;
LineDefinition.isAutoScale = true;
LineDefinition.name = "Line";
LineDefinition.parameters = [
  makePointParameter("point1", new Vec()),
  makePointParameter("point2", new Vec(1)),
];
LineDefinition.code = new Expression("Path.fromPoints([point1, point2]);");
registerBuiltin("LineDefinition", LineDefinition);

export const ArcFromCenterAndAnglesDefinition = new CodeComponent();
ArcFromCenterAndAnglesDefinition.isImmutable = true;
ArcFromCenterAndAnglesDefinition.isPassThrough = true;
ArcFromCenterAndAnglesDefinition.isShowGuides = true;
ArcFromCenterAndAnglesDefinition.isAutoScale = true;
ArcFromCenterAndAnglesDefinition.name = "Arc From Center And Angles";
ArcFromCenterAndAnglesDefinition.parameters = [
  makePointParameter("center", new Vec()),
  makeDistanceParameter("radius", 1),
  makeAngleParameter("startAngle", 0),
  makeAngleParameter("endAngle", 90),
];
ArcFromCenterAndAnglesDefinition.code = new Expression(
  `console.guide(center);
return Path.fromArc(center, radius, startAngle, endAngle);`
);
registerBuiltin("ArcDefinition", ArcFromCenterAndAnglesDefinition);

export const ArcFromCenterAndPointsDefinition = new CodeComponent();
ArcFromCenterAndPointsDefinition.isImmutable = true;
ArcFromCenterAndPointsDefinition.isPassThrough = true;
ArcFromCenterAndPointsDefinition.isShowGuides = true;
ArcFromCenterAndPointsDefinition.isAutoScale = true;
ArcFromCenterAndPointsDefinition.name = "Arc From Center And Points";
ArcFromCenterAndPointsDefinition.parameters = [
  makePointParameter("center", new Vec()),
  makePointParameter("point1", new Vec(1, 0)),
  makePointParameter("point2", new Vec(0, 1)),
  makeBooleanParameter("clockwise", true),
];
ArcFromCenterAndPointsDefinition.code = new Expression(
  `console.geometry(LineSegment(center, point1));
console.geometry(LineSegment(center, point2));
console.guide(center);

const startDir = point1 - center;
const endDir = point2 - center;
let startAngle = startDir.angle();
let endAngle = endDir.angle();

// Swap angles if we're not going clockwise.
if (!clockwise) {
  let tempAngle = startAngle;
  startAngle = endAngle;
  endAngle = tempAngle;
}

// Make sure the endAngle is greater than startAngle.
if (endAngle < startAngle) endAngle += 360;

const radius = startDir.length();
return Path.fromArc(center, radius, startAngle, endAngle);`
);
registerBuiltin("ArcFromCenterAndPointsDefinition", ArcFromCenterAndPointsDefinition);

export const ArcFromThreePointsDefinition = new CodeComponent();
ArcFromThreePointsDefinition.isImmutable = true;
ArcFromThreePointsDefinition.isPassThrough = true;
ArcFromThreePointsDefinition.isShowGuides = true;
ArcFromThreePointsDefinition.isAutoScale = true;
ArcFromThreePointsDefinition.name = "Arc From Three Points";
ArcFromThreePointsDefinition.parameters = [
  makePointParameter("point1", new Vec(-1, 0)),
  makePointParameter("point2", new Vec(0, -1)),
  makePointParameter("point3", new Vec(1, 0)),
];
ArcFromThreePointsDefinition.code = new Expression(
  `const lineLineIntersection = (p1, p2, p3, p4) => {
  const denom = (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);
  return ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denom;
};

const mid1 = mix(point1, point2, 0.5);
const mid2 = mix(point2, point3, 0.5);
const bisector1 = (point2 - point1).rotateNeg90();
const bisector2 = (point3 - point2).rotateNeg90();
const d = lineLineIntersection(mid1, bisector1 + mid1, mid2, bisector2 + mid2);

// Replace very large circles with straight lines.
// Without this, numerical imprecision would give an ugly result.
if (abs(d) > 1000000000000) {
  return Path.fromPoints([point1, point3]);
}

const center = bisector1 * d + mid1;
console.guide(center);

const radius = point1.distance(center);
let angle1 = (point1 - center).angle() + 360;
let angle2 = (point2 - center).angle() + 360;
let angle3 = (point3 - center).angle() + 360;

const ab = point1 - point2;
const cd = point3 - point2;
const determinant = ab.x * cd.y - ab.y * cd.x;

if (angle1 < angle3) {
  if (angle2 > angle3) {
    angle3 -= 360;
  } else if (angle2 < angle1) {
    angle1 += 360;
  }
} else {
  if (determinant < 0) {
    angle3 += 360;
  }
}

return Path.fromArc(center, radius, angle1, angle3);`
);
registerBuiltin("ArcFromThreePointsDefinition", ArcFromThreePointsDefinition);

export const TextDefinition = new CodeComponent();
TextDefinition.isImmutable = true;
TextDefinition.isShowGuides = true;
TextDefinition.isAutoScale = true;
TextDefinition.name = "Text";
TextDefinition.parameters = [
  makeTextParameter("text", "Aa"),
  makeFontSelectParameter(
    "font",
    "https://fonts.gstatic.com/s/notosans/v26/o-0IIpQlx3QUlC5A4PNb4j5Ba_2c7A.ttf" // Noto Sans Regular
  ),
  makeDistanceParameter("size", 1),
  makeSelectParameter("align", "left", ["left", "center", "right"]),
  makeSelectParameter("verticalAlign", "baseline", ["top", "middle", "baseline", "bottom"]),
  makePercentageParameter("letterSpacing", 0, 3),
  makeScalarParameter("lineHeight", 1, 3),
];
TextDefinition.code = new Expression(
  `const myFont = getFontFromURL(font);
if (!myFont) return [];

const linesInfo = myFont.render(text, { letterSpacing });

const lineGroups = [];

for (let i = 0, len = linesInfo.length; i < len; i++) {
  const line = linesInfo[i];
  const { geometry, advanceX } = line;

  const position = new Vec();

  // Horizontal Alignment
  if (align === "center") {
    position.x -= advanceX / 2;
  } else if (align === "right") {
    position.x -= advanceX;
  }

  // Multiline
  position.y += i * lineHeight;

  // Vertical Alignment
  if (verticalAlign === "top") {
    position.y += myFont.ascenderHeight;
  } else if (verticalAlign === "middle") {
    position.y -= ((len - 1) * lineHeight) / 2;
    position.y += (myFont.ascenderHeight + myFont.descenderHeight) / 2;
  } else if (verticalAlign === "bottom") {
    position.y -= (len - 1) * lineHeight;
    position.y += myFont.descenderHeight;
  }

  position.mulScalar(size);
  geometry.transform({ position, scale: size });

  lineGroups.push(geometry);
}

return new Group(lineGroups);`
);
registerBuiltin("TextDefinition", TextDefinition);

export const ConnectedTextDefinition = new CodeComponent();
ConnectedTextDefinition.name = "Connected Text";
ConnectedTextDefinition.isImmutable = true;
ConnectedTextDefinition.parameters = [
  makeTextParameter("text", "Connected"),
  makeFontSelectParameter(
    "font",
    "https://fonts.gstatic.com/s/pacifico/v22/FwZY7-Qmy14u9lezJ96A4sijpFu_.ttf"
  ),
  new Parameter("size", "72 pt", new PIScalar()),
  makeSelectParameter("align", "left", ["left", "center", "right"]),
  makeSelectParameter("verticalAlign", "baseline", ["top", "middle", "baseline", "bottom"]),
  makePercentageParameter("lineHeight", 0.8, 3),
  makePercentageParameter("thicken", 0, 3).setComment(
    `Adds an "Expand" to your font, to help prevent delicate fonts from breaking apart.`
  ),
  makeSelectParameter("welding", "Weld everything (default)", [
    "Weld everything (default)",
    "Weld individual letters",
    "Weld nothing",
  ]),
];
ConnectedTextDefinition.code = new Expression(`const myFont = getFontFromURL(font);
if (!myFont) return;

// Extend myFont with a new glyphsFromString implementation that connects glyphs.
const newFont = Object.create(myFont);
newFont.glyphsFromString = (text, options) => {
  const richText = RichText(text);
  const lineGlyphs = [];
  let prevItem;
  for (const item of richText.items) {
    const glyphs = myFont.glyphsFromString(item);
    for (const glyph of glyphs) {
      const glyphThicken = item instanceof RichTextSymbol ? 0 : thicken;
      glyph.connect({ thicken: glyphThicken });

      if (welding === "Weld individual letters") {
        glyph.geometry = CompoundPath.booleanUnion([glyph.geometry]);
      }

      const ignoreInitialConnection =
        item instanceof RichTextSymbol ||
        (prevItem instanceof RichTextSymbol && glyph === glyphs[0]);

      glyph.connectToLine(lineGlyphs, { ignoreInitialConnection });
      lineGlyphs.push(glyph);
    }
    prevItem = item;
  }
  return lineGlyphs;
};

const linesInfo = newFont.render(text, { letterSpacing: 0 });
const lines = [];

for (let i = 0, len = linesInfo.length; i < len; i++) {
  const line = linesInfo[i];
  let { geometry, advanceX } = line;

  const position = new Vec();

  // Horizontal Alignment
  if (align === "center") {
    position.x -= advanceX / 2;
  } else if (align === "right") {
    position.x -= advanceX;
  }

  // Multiline
  position.y += i * lineHeight;

  // Vertical Alignment
  if (verticalAlign === "top") {
    position.y += myFont.ascenderHeight;
  } else if (verticalAlign === "middle") {
    position.y -= ((len - 1) * lineHeight) / 2;
    position.y += (myFont.ascenderHeight + myFont.descenderHeight) / 2;
  } else if (verticalAlign === "bottom") {
    position.y -= (len - 1) * lineHeight;
    position.y += myFont.descenderHeight;
  }

  position.mulScalar(size);
  geometry.transform({ position, scale: size });

  lines.push(geometry);
}

let output;
if (welding === "Weld everything (default)") {
  output = CompoundPath.booleanUnion(lines);
} else {
  output = Group(lines);
}

return output;`);
registerBuiltin("ConnectedTextDefinition", ConnectedTextDefinition);

export const ConnectedTextWithTailsDefinition = new CodeComponent();
ConnectedTextWithTailsDefinition.name = "Connected Text With Tails";
ConnectedTextWithTailsDefinition.isImmutable = true;
ConnectedTextWithTailsDefinition.isPro = true;
ConnectedTextWithTailsDefinition.parameters = [
  makeTextParameter("text", "love"),
  makeFontSelectParameter(
    "font",
    "https://assets.cuttle.xyz/fonts/pro/Hilda-Boby-Script.otf.json",
    [
      "https://assets.cuttle.xyz/fonts/pro/Angela-Lovely.otf.json",
      "https://assets.cuttle.xyz/fonts/pro/Emily-Bonza-Script.otf.json",
      "https://assets.cuttle.xyz/fonts/pro/Gloding-Sophia-Script.otf.json",
      // "https://assets.cuttle.xyz/fonts/pro/Goldie-Rainbow.otf.json",
      "https://assets.cuttle.xyz/fonts/pro/Hello-Hana-Script.otf.json",
      "https://assets.cuttle.xyz/fonts/pro/Hello-Yastina-Script.otf.json",
      "https://assets.cuttle.xyz/fonts/pro/Hilda-Boby-Script.otf.json",
      "https://assets.cuttle.xyz/fonts/pro/Jasmie-Martin-Script.otf.json",
      "https://assets.cuttle.xyz/fonts/pro/Misha-Script.otf.json",
      // "https://assets.cuttle.xyz/fonts/pro/Penthouse.otf.json",
      "https://assets.cuttle.xyz/fonts/pro/Selina-Script.otf.json",
      "https://assets.cuttle.xyz/fonts/pro/Smith-family-Script.otf.json",
    ]
  ),
  makeSelectParameter("startStyle", "tail", ["none", "tail"]),
  makeSelectParameter("betweenStyle", "heart", ["auto", "heart", "none"]).setComment(
    '"auto" matches the left and right tail options.\n"heart" connects words with a heart.\n"none" leaves spaces between words.'
  ),
  makeSelectParameter("endStyle", "tail", ["none", "tail"]),
  new Parameter("size", "72 pt", new PIScalar()),
  makeSelectParameter("align", "left", ["left", "center", "right"]),
  makeSelectParameter("verticalAlign", "baseline", ["top", "middle", "baseline", "bottom"]),
  makePercentageParameter("lineHeight", 0.8, 3),
  makePercentageParameter("thicken", 0, 3).setComment(
    `Adds an "Expand" to your font, to help prevent delicate fonts from breaking apart.`
  ),
  makeSelectParameter("welding", "Weld everything (default)", [
    "Weld everything (default)",
    "Weld individual letters",
    "Weld nothing",
  ]),
];
ConnectedTextWithTailsDefinition.code = new Expression(
  `const myFont = getFontFromURL(font);
if (!myFont) return [];

const pattern = {};
if (startStyle === "tail") {
  pattern.wordStart = "leftTail";
}
if (betweenStyle === "heart") {
  pattern.wordConnect = "rightHeart";
} else if (betweenStyle === "none") {
  pattern.wordConnect = "skip";
}
if (endStyle === "tail") {
  pattern.wordEnd = "rightTail";
}

const replacedText = myFont.replacePatternsWithGlyphAlternates(text, pattern);

return ConnectedText({
  text: replacedText,
  font,
  size,
  align,
  verticalAlign,
  lineHeight,
  thicken,
  welding
})`
);
registerBuiltin("ConnectedTextWithTailsDefinition", ConnectedTextWithTailsDefinition);

export let ImageFrameDefinition: Component;
{
  const rectElement = new Element(new Instance(RectangleDefinition));
  rectElement.name = "Rectangle 1";
  rectElement.transform = new Instance(TransformDefinition);
  rectElement.transform.args.scale = new Expression(`const img = getImageFromURL(image);
if (img) {
  return Vec(img.width, img.height);
}
return Vec(1, 1);`);
  rectElement.fill = new Instance(FillDefinition);
  rectElement.fill.args.type = new Expression(`"image"`);
  rectElement.fill.args.image = new Expression("image");
  rectElement.fill.args.opacity = new Expression("opacity");

  ImageFrameDefinition = new Component(new Element(new Instance(GroupDefinition)));
  ImageFrameDefinition.isImmutable = true;
  ImageFrameDefinition.name = "Image Frame";
  ImageFrameDefinition.parameters = [
    makeImageParameter("image", ""),
    makePercentageParameter("opacity", 1, 0),
  ];
  ImageFrameDefinition.element.children = [rectElement];
  registerBuiltin("ImageFrameDefinition", ImageFrameDefinition);
}

export const EmojiDefinition = new CodeComponent();
EmojiDefinition.isImmutable = true;
EmojiDefinition.isShowGuides = true;
EmojiDefinition.isAutoScale = true;
EmojiDefinition.name = "Emoji";
EmojiDefinition.parameters = [
  makeEmojiParameter(
    "emoji",
    `${canonicalAssetsOrigin}/noto-emoji-600/1f642.svg` // :slightly_smiling_face:
  ),
];
EmojiDefinition.code = new Expression(`const emojiShape = getEmojiSVGFromURL(emoji);
if (!emojiShape) return [];
return emojiShape;`);
EmojiDefinition.defaultStyle = "fill";
EmojiDefinition.icon = "builtin_shape_emoji";
registerBuiltin("EmojiDefinition", EmojiDefinition);

export const SmoothFunctionPlotDefinition = new CodeComponent();
SmoothFunctionPlotDefinition.isImmutable = true;
SmoothFunctionPlotDefinition.isShowGuides = true;
SmoothFunctionPlotDefinition.isAutoScale = true;
SmoothFunctionPlotDefinition.name = "Function Plot";
SmoothFunctionPlotDefinition.parameters = [
  new Parameter("func", "(t) => Vec(t / 90, sin(t))"),
  makeScalarParameter("domainStart", -180),
  makeScalarParameter("domainEnd", 180),
  makeScalarParameter("tolerance", 0.001),
];
SmoothFunctionPlotDefinition.code = new Expression(
  `return Path.fromSmoothFunction(func, domainStart, domainEnd, tolerance);`
);
registerBuiltin("SmoothFunctionPlotDefinition", SmoothFunctionPlotDefinition);

export let ArchimedeanSpiralDefinition: Component;
{
  const plot = new Element(new Instance(SmoothFunctionPlotDefinition));
  plot.name = "Function Plot 1";
  plot.base.args.func = new Expression(`(t) => t / rotation * Vec.fromAngle(rotation - t)`);
  plot.base.args.domainStart = new Expression("rotation");
  plot.base.args.domainEnd = new Expression("0.0");
  plot.base.args.tolerance = new Expression("0.0001");
  ArchimedeanSpiralDefinition = new Component(new Element(new Instance(GroupDefinition)));
  ArchimedeanSpiralDefinition.isImmutable = true;
  ArchimedeanSpiralDefinition.isShowGuides = true;
  ArchimedeanSpiralDefinition.isAutoScale = true;
  ArchimedeanSpiralDefinition.name = "Archimedean Spiral";
  ArchimedeanSpiralDefinition.parameters = [
    makeScalarParameter("revolutions", 3),
    new Parameter("rotation", "360 * revolutions"),
  ];
  ArchimedeanSpiralDefinition.element.children = [plot];
  registerBuiltin("ArchimedeanSpiralDefinition", ArchimedeanSpiralDefinition);
}

export let LogarithmicSpiralDefinition: Component;
{
  const plot = new Element(new Instance(SmoothFunctionPlotDefinition));
  plot.name = "Function Plot 1";
  plot.base.args.func = new Expression(
    `(t) => Math.pow(360, tan(pitch) * t / -360) * Vec.fromAngle(t)`
  );
  plot.base.args.domainStart = new Expression("rotation");
  plot.base.args.domainEnd = new Expression("0.0");
  plot.base.args.tolerance = new Expression("0.00001");
  LogarithmicSpiralDefinition = new Component(new Element(new Instance(GroupDefinition)));
  LogarithmicSpiralDefinition.isImmutable = true;
  LogarithmicSpiralDefinition.isShowGuides = true;
  LogarithmicSpiralDefinition.isAutoScale = true;
  LogarithmicSpiralDefinition.name = "Logarithmic Spiral";
  LogarithmicSpiralDefinition.parameters = [
    makeScalarParameter("revolutions", 3),
    new Parameter("rotation", "360 * revolutions"),
    makeAngleParameter("pitch", 12),
  ];
  LogarithmicSpiralDefinition.element.children = [plot];
  registerBuiltin("LogarithmicSpiralDefinition", LogarithmicSpiralDefinition);
}

// Legacy Shapes

export let OldCircleDefinition: Component;
{
  const circleAnchor1 = new Element(new Instance(AnchorDefinition));
  circleAnchor1.name = "Anchor 1";
  circleAnchor1.base.args.position = new Expression("Vec(radius, 0)");
  circleAnchor1.base.args.handleIn = new Expression("Vec(0, -c)");
  circleAnchor1.base.args.handleOut = new Expression("Vec(0, c)");
  circleAnchor1.base.args.handleConstraint = new Expression(`"tangent"`);
  const circleAnchor2 = new Element(new Instance(AnchorDefinition));
  circleAnchor2.name = "Anchor 2";
  circleAnchor2.base.args.position = new Expression("Vec(0, radius)");
  circleAnchor2.base.args.handleIn = new Expression("Vec(c, 0)");
  circleAnchor2.base.args.handleOut = new Expression("Vec(-c, 0)");
  circleAnchor2.base.args.handleConstraint = new Expression(`"tangent"`);
  const circleAnchor3 = new Element(new Instance(AnchorDefinition));
  circleAnchor3.name = "Anchor 3";
  circleAnchor3.base.args.position = new Expression("Vec(-radius, 0)");
  circleAnchor3.base.args.handleIn = new Expression("Vec(0, c)");
  circleAnchor3.base.args.handleOut = new Expression("Vec(0, -c)");
  circleAnchor3.base.args.handleConstraint = new Expression(`"tangent"`);
  const circleAnchor4 = new Element(new Instance(AnchorDefinition));
  circleAnchor4.name = "Anchor 4";
  circleAnchor4.base.args.position = new Expression("Vec(0, -radius)");
  circleAnchor4.base.args.handleIn = new Expression("Vec(-c, 0)");
  circleAnchor4.base.args.handleOut = new Expression("Vec(c, 0)");
  circleAnchor4.base.args.handleConstraint = new Expression(`"tangent"`);
  const circlePath = new Element(new Instance(PathDefinition));
  circlePath.name = "Path 1";
  circlePath.children = [circleAnchor1, circleAnchor2, circleAnchor3, circleAnchor4];
  circlePath.base.args.closed = new Expression("true");

  OldCircleDefinition = new Component(new Element(new Instance(GroupDefinition)));
  OldCircleDefinition.isImmutable = true;
  OldCircleDefinition.name = "Circle (old)";
  OldCircleDefinition.isDefaultUniformScale = true;
  OldCircleDefinition.parameters = [
    makeDistanceParameter("radius", 1),
    new Parameter("c", "radius * 0.551915024494"),
  ];
  OldCircleDefinition.element.children = [circlePath];
  registerBuiltin("CircleDefinition", OldCircleDefinition);
}

export let OldRectangleDefinition1: Component;
{
  const anchor1 = new Element(new Instance(AnchorDefinition));
  anchor1.name = "Anchor 1";
  anchor1.base.args.position = new Expression("Vec(0.0, 0.0)");
  const anchor2 = new Element(new Instance(AnchorDefinition));
  anchor2.name = "Anchor 2";
  anchor2.base.args.position = new Expression("Vec(1.0, 0.0)");
  const anchor3 = new Element(new Instance(AnchorDefinition));
  anchor3.name = "Anchor 3";
  anchor3.base.args.position = new Expression("Vec(1.0, 1.0)");
  const anchor4 = new Element(new Instance(AnchorDefinition));
  anchor4.name = "Anchor 4";
  anchor4.base.args.position = new Expression("Vec(0.0, 1.0)");

  const path = new Element(new Instance(PathDefinition));
  path.name = "Path 1";
  path.children = [anchor1, anchor2, anchor3, anchor4];
  path.base.args.closed = new Expression("true");

  const center = new Element(new Instance(PointGuideDefinition));
  center.name = "Point Guide 1";
  center.base.args.position = new Expression("Vec(0.5, 0.5)");

  OldRectangleDefinition1 = new Component(new Element(new Instance(GroupDefinition)));
  OldRectangleDefinition1.isImmutable = true;
  OldRectangleDefinition1.isDefaultUniformScale = false;
  OldRectangleDefinition1.name = "Rectangle (old)";
  OldRectangleDefinition1.element.children = [path, center];
  registerBuiltin("RectangleDefinition1", OldRectangleDefinition1);
}

export let OldRectangleDefinition: Component;
{
  const rectAnchor1 = new Element(new Instance(AnchorDefinition));
  rectAnchor1.name = "Anchor 1";
  rectAnchor1.base.args.position = new Expression("Vec(0, 0)");
  const rectAnchor2 = new Element(new Instance(AnchorDefinition));
  rectAnchor2.name = "Anchor 2";
  rectAnchor2.base.args.position = new Expression("Vec(size.x, 0)");
  const rectAnchor3 = new Element(new Instance(AnchorDefinition));
  rectAnchor3.name = "Anchor 3";
  rectAnchor3.base.args.position = new Expression("Vec(size.x, size.y)");
  const rectAnchor4 = new Element(new Instance(AnchorDefinition));
  rectAnchor4.name = "Anchor 4";
  rectAnchor4.base.args.position = new Expression("Vec(0, size.y)");
  const rectPath = new Element(new Instance(PathDefinition));
  rectPath.name = "Path 1";
  rectPath.children = [rectAnchor1, rectAnchor2, rectAnchor3, rectAnchor4];
  rectPath.base.args.closed = new Expression("true");
  rectPath.transform = new Instance(TransformDefinition);
  rectPath.transform.args.position = new Expression("centered ? size * -0.5 : Vec(0, 0)");
  const rectRoundCorners = new Instance(RoundCornersDefinition);
  rectRoundCorners.args.radius = new Expression(
    "min(abs(size.x)/2-.01, abs(size.y)/2-.01, cornerRadius)"
  );
  rectPath.modifiers.push(rectRoundCorners);

  OldRectangleDefinition = new Component(new Element(new Instance(GroupDefinition)));
  OldRectangleDefinition.isImmutable = true;
  OldRectangleDefinition.name = "Rectangle (old)";
  OldRectangleDefinition.parameters = [
    makeSizeParameter("size", new Vec(1)),
    makeBooleanParameter("centered", false),
    makeDistanceParameter("cornerRadius", 0),
  ];
  OldRectangleDefinition.element.children = [rectPath];
  registerBuiltin("RectangleDefinition", OldRectangleDefinition);
}

export let OldPolygonDefinition: Component;
{
  const polygonAnchor = new Element(new Instance(AnchorDefinition));
  polygonAnchor.name = "Anchor 1";
  polygonAnchor.base.args.position = new Expression("Vec(0, -radius)");
  const polygonRepeat = new Instance(RotationalRepeatDefinition);
  polygonRepeat.args.repetitions = new Expression("sides");
  polygonAnchor.modifiers.push(polygonRepeat);
  const polygonPath = new Element(new Instance(PathDefinition));
  polygonPath.name = "Path 1";
  polygonPath.base.args.closed = new Expression("true");
  polygonPath.children = [polygonAnchor];

  OldPolygonDefinition = new Component(new Element(new Instance(GroupDefinition)));
  OldPolygonDefinition.isImmutable = true;
  OldPolygonDefinition.isDefaultUniformScale = true;
  OldPolygonDefinition.name = "Polygon (old)";
  OldPolygonDefinition.parameters = [
    makeCountParameter("sides", 5),
    makeDistanceParameter("radius", 1),
  ];
  OldPolygonDefinition.element.children = [polygonPath];
  registerBuiltin("PolygonDefinition", OldPolygonDefinition);
}
