import m from "mithril";

import { globalState } from "../../global-state";
import { LogLevel, LogMessage } from "../../log-manager";
import { CodeComponent, Component } from "../../model/component";
import { InstanceTrace } from "../../model/trace";
import { traceUniqueMessages } from "../../model/trace-reducer";
import { Icon20 } from "../../shared/icon";

interface InstanceMessagesAttrs {
  trace: InstanceTrace | undefined;
  showLogLevels: LogLevel[];
}
export const InstanceMessages: m.Component<InstanceMessagesAttrs> = {
  view({ attrs: { trace, showLogLevels } }) {
    if (trace === undefined) return;

    const messages = trace
      .expressionTraces()
      .flatMap((expressionTrace) => expressionTrace.messages);
    if (messages.length < 1) return;

    const messagesToShow = messages.filter((message) => showLogLevels.includes(message.logLevel));
    if (messagesToShow.length < 1) return;

    return messages.map((message) => m(InstanceMessage, { message }));
  },
};

interface ComponentElementMessagesAttrs {
  component: Component | CodeComponent;
  showLogLevels: LogLevel[];
}
export const ComponentElementMessages: m.Component<ComponentElementMessagesAttrs> = {
  view({ attrs: { component, showLogLevels } }) {
    // Skip CodeComponents since they don't have elements.
    if (component instanceof CodeComponent) return;

    const trace = globalState.traceForComponent(component);
    if (!trace) return;
    if (!trace.element) return;

    const messages = traceUniqueMessages(trace.element).filter((message) =>
      showLogLevels.includes(message.logLevel)
    );
    if (messages.length < 1) return;

    return messages.map((message) => m(InstanceMessage, { message }));
  },
};

interface InstanceMessageAttrs {
  message: LogMessage;
}
const InstanceMessage: m.Component<InstanceMessageAttrs> = {
  view({ attrs: { message } }) {
    if (message.logLevel === "error") {
      return m(".instance-inspector-message-error", [
        m(
          ".instance-inspector-message-icon",
          { "aria-label": "Error" },
          m(Icon20, { icon: "error" })
        ),
        m(".instance-inspector-message-text", makeFriendlyErrorMessage(String(message.message))),
      ]);
    }
    if (message.logLevel === "warn") {
      return m(".instance-inspector-message-warning", [
        m(
          ".instance-inspector-message-icon",
          { "aria-label": "Warning" },
          m(Icon20, { icon: "warning" })
        ),
        m(".instance-inspector-message-text", String(message.message)),
      ]);
    }
    return m(".instance-inspector-message-info", [
      m(".instance-inspector-message-icon", { "aria-label": "Info" }, m(Icon20, { icon: "info" })),
      m(".instance-inspector-message-text", String(message.message)),
    ]);
  },
};

function makeFriendlyErrorMessage(message: string) {
  if (message.includes("MALLOC")) {
    return "Cuttle has run out of memory. Please reduce the number of shapes in your design. You might have to refresh the browser to continue.";
  }
  return message;
}
