import { CuttleContext } from "../docs";
import { globalEnvironment as baseGlobalEnv } from "../environment/global-environment";
import { Graphic } from "../geom";
import { normalizeGraphic } from "../geom-internal";
import { convertUnits } from "../geom/env/convert-units";
import { logManager } from "../log-manager";
import { sanitizeName } from "../util";
import { allBuiltins } from "./builtin";
import { CodeComponent, Component } from "./component";
import { Env } from "./env";
import { Modifier } from "./modifier";
import { Project } from "./project";
import { ProjectEvaluator } from "./project-evaluator";
import { traceResultOrError } from "./trace";
import { traceAllMessages } from "./trace-reducer";

export const makeGlobalEnv = (project: Project, evaluator: ProjectEvaluator) => {
  // Note: although we re-create the first-class Component and Modifier
  // functions on each evaluation, we assert that they are equal (for the
  // purposes of memoization) by setting `_memoizeEqualityId`. Since using a
  // first-class Component or Modifier necessitates looking at the Component or
  // Modifier (and other ModelObjects), they will automatically trigger cache
  // invalidation when they change.

  const globalEnv = new Env(baseGlobalEnv);

  // Add project Component and Modifier functions
  for (let component of project.components) {
    addComponentFunction(component, globalEnv, evaluator);
  }
  for (let modifier of project.modifiers) {
    addModifierFunction(modifier, globalEnv, evaluator);
  }

  ToProjectUnits: {
    /**
     * This function was never documented, but is used in forks of the "Box with
     * Dividers" project.
     *
     * TODO: Add a snapshot upgrade to remove these uses.
     *
     * @deprecated
     */
    const toProjectUnits = (value: unknown, sourceUnit: unknown) => {
      return convertUnits(value, sourceUnit, project.settings.units);
    };
    (toProjectUnits as any)._memoizeEqualityId = "to-project-units/" + project.settings.units;
    globalEnv.set("toProjectUnits", toProjectUnits);
  }

  CuttleContext: {
    const cuttleContext: CuttleContext = {
      project: {
        get units() {
          return project.settings.units;
        },
      },
    };
    (cuttleContext as any)._memoizeEqualityId = "cuttle-context/" + project.settings.units;
    globalEnv.set("cuttle", cuttleContext);
  }

  // Add builtin Component and Modifier functions after project to prevent them
  // from clobbering the builtin names.
  //
  // TODO: Probably want another way to add all the built-in definitions
  // (whatever way all the built-in definitions get into the Shapes and
  // Modifiers menu).
  for (let definition of allBuiltins) {
    if (definition instanceof Component || definition instanceof CodeComponent) {
      addComponentFunction(definition, globalEnv, evaluator);
    }
    if (definition instanceof Modifier) {
      const excludedModifierNames = ["Anchor", "Path", "Compound Path", "Group", "Fill", "Stroke"];
      if (!excludedModifierNames.includes(definition.name)) {
        addModifierFunction(definition, globalEnv, evaluator);
      }
    }
  }

  Console: {
    const cuttleConsole = {
      log: (...args: unknown[]) => logManager.console("log", ...args),
      info: (...args: unknown[]) => logManager.console("info", ...args),
      warn: (...args: unknown[]) => logManager.console("warn", ...args),
      error: (...args: unknown[]) => logManager.console("error", ...args),
      geometry: (...args: unknown[]) => logManager.console("geometry", ...args),
      guide: (...args: unknown[]) => logManager.console("guide", ...args),
    };
    (cuttleConsole as any)._memoizeEqualityId = "cuttle-console";
    globalEnv.set("console", cuttleConsole);
  }

  return globalEnv;
};

const addComponentFunction = (
  component: Component | CodeComponent,
  globalEnv: Env,
  evaluator: ProjectEvaluator
) => {
  const sanitizedName = sanitizeName(component.name);
  // Assign the function as a property of a wrapper object so that the component
  // name is assigned to fn.name. Safari assigns an empty string if the property
  // name comtains a space, so we must use the sanitized name here.
  const fnWrapper = {
    [sanitizedName]: (args: Record<string, unknown> = {}) => {
      const trace = evaluator.evaluateComponentFunction(component, args, globalEnv);
      // Re-emit any messages in the trace.
      for (const message of traceAllMessages(trace)) {
        logManager.consoleMessage(message);
      }
      return traceResultOrError(trace);
    },
  };
  const fn = ComponentFunction.create(fnWrapper[sanitizedName]);
  (fn as any)._memoizeEqualityId = "first-class-definition/" + component.id;
  globalEnv.set(sanitizedName, fn);
};

const addModifierFunction = (modifier: Modifier, globalEnv: Env, evaluator: ProjectEvaluator) => {
  const sanitizedName = sanitizeName(modifier.name);
  // Assign the function as a property of a wrapper object so that the component
  // name is assigned to fn.name. Safari assigns an empty string if the property
  // name comtains a space, so we must use the sanitized name here.
  const fnWrapper = {
    [sanitizedName]: (args: Record<string, unknown> = {}, input: unknown) => {
      input = normalizeGraphic(input);
      const trace = evaluator.evaluateModifierFunction(modifier, args, input, globalEnv);
      // Re-emit any messages in the trace.
      for (const message of traceAllMessages(trace)) {
        logManager.consoleMessage(message);
      }
      return traceResultOrError(trace);
    },
  };
  const fn = ModifierFunction.create(fnWrapper[sanitizedName]);
  (fn as any)._memoizeEqualityId = "first-class-definition/" + modifier.id;
  globalEnv.set(sanitizedName, fn);
};

class ComponentFunction extends Function {
  withDefaults(defaultArgs: { [name: string]: unknown }) {
    const fnWrapper = {
      [this.name]: (args: { [name: string]: unknown } = {}) => {
        return this({ ...defaultArgs, ...args });
      },
    };
    return ComponentFunction.create(fnWrapper[this.name]);
  }
  static create(fn: (args: { [name: string]: unknown }) => void) {
    Object.setPrototypeOf(fn, ComponentFunction.prototype);
    return fn;
  }
}

class ModifierFunction extends Function {
  withDefaults(defaultArgs: { [name: string]: unknown }) {
    const fnWrapper = {
      [this.name]: (args: { [name: string]: unknown } = {}, input: Graphic) => {
        return this({ ...defaultArgs, ...args }, input);
      },
    };
    return ModifierFunction.create(fnWrapper[this.name]);
  }
  static create(fn: (args: { [name: string]: unknown }, input: Graphic) => void) {
    Object.setPrototypeOf(fn, ModifierFunction.prototype);
    return fn;
  }
}
